<template>
  <v-navigation-drawer
    v-model="opened"
    absolute
    temporary
  >
    <router-link to="/" class="text-decoration-none d-block">
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="require('../assets/logo.svg')"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="name">Trash Panda</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </router-link>
    <v-divider></v-divider>
<!--    <v-container>-->
<!--      <router-link to="/order/1" class="text-decoration-none d-block">-->
<!--        <v-btn block elevation="2"-->
<!--               color="green darken-2"-->
<!--               dark-->
<!--               class="custom-btn"-->
<!--        >Сдать-->
<!--        </v-btn>-->
<!--      </router-link>-->
<!--    </v-container>-->

    <v-list dense>
      <v-list-item
        style="padding: 8px 16px"
        v-for="item in items"
        :key="item.title"
        link
      >
        <v-list-item-icon style="margin-right: 6px">
          <v-icon large>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <router-link v-if="item.link" :to="item.link" class="text-decoration-none d-block">
            <v-list-item-title class="menu-items">{{ item.title }}</v-list-item-title>
          </router-link>
          <a v-if="item.ext_link" :href="item.ext_link" class="text-decoration-none d-block">
            <v-list-item-title class="menu-items">{{ item.title }}</v-list-item-title>
          </a>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-6">
        <v-list-item-content>
          <router-link :to="'/about'" class="text-decoration-underline d-block pb-2 text-sm-center">
            <v-list-item-title class="menu-items small-text">О компании</v-list-item-title>
          </router-link>
          <router-link :to="'/privacy'" class="text-decoration-underline d-block text-sm-center">
            <v-list-item-title class="menu-items small-text">Конфиденциальность</v-list-item-title>
          </router-link>
        </v-list-item-content>
      </div>
    </template>

  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'Menu',
  props: ['openMenu'],
  data: () => ({
    opened: false,
    items: [
      // { title: 'Сделать заказ', icon: 'mdi-forum', link: '/order/1' },
      { title: 'Связаться по Whatsapp', icon: 'mdi-whatsapp', target: '_blank', ext_link: 'https://api.whatsapp.com/send?phone=' + '79381721955' + '&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D1%8F%20%D0%BF%D0%BE%20%D0%BF%D0%BE%D0%B2%D0%BE%D0%B4%D1%83...' },
      { title: 'Позвонить оператору', icon: 'mdi-phone', ext_link: 'tel:+79381721955' },
      { title: 'Последние заказы', icon: 'mdi-history', link: '/history' }
    ]
  }),
  methods: {
    toggle () {
      this.opened = !this.opened
    }
  }
}
</script>
<style>
.menu-items {
  font-size: 1rem !important;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.name {
  font-size: 1.3rem;
  font-weight: bold;
}

.custom-btn {
  border-radius: 0px !important;
  height: 52px !important;
  box-shadow: none !important;
}

.v-btn.addmore-btn {
  border-radius: 8px;
  height: 32px !important;
  box-shadow: none !important;
  font-size: 14px !important;
  line-height: 18px;
  text-transform: none;
  color: #2688EB !important;
  background: #fff !important;
}

.small-text {
  font-size: 15px !important;
  font-weight: 400 !important;
}

@media (max-width: 960px) {
  .custom-btn {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .custom-btn {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .custom-btn {
    width: 100%;
  }
}

.mdi-phone::before,
.mdi-whatsapp::before {
  color: green;
}

</style>
